<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{$vuetify.lang.t('$vuetify.role.label')}}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form
          ref="form"
        >
          <v-row>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="form.roleName"
                :label="$vuetify.lang.t('$vuetify.role.roleName')"
                :rules="rules.roleName"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="form.showName"
                :label="$vuetify.lang.t('$vuetify.role.showName')"
                :rules="rules.showName"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="form.description"
                :label="$vuetify.lang.t('$vuetify.common.description')"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        @click="handleSubmit"
      >
         {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      editStatus: {
        type: Boolean,
        default: () => false
      },
      editItem: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        form: {
          id: null,
          roleName: null,
          showName: null,
          description: null
        },
        rules: {
          roleName: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.role.roleName')])
          ],
          showName: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.role.showName')])
          ]
        }
      }
    },
    computed: {},
    created () {
      this.initialize()
    },
    methods: {
      initialize () {
        if (this.editStatus) {
          for (let p in this.form) {
            if (p in this.editItem) {
              this.form[p] = this.editItem[p]
            } else {
              this.form[p] = null
            }
          }
        } else {
          this.resetFrom()
        }
        this.$nextTick( () => {
          this.$refs.form.resetValidation()
        })
      },
      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
      },
      handleCancel() {
        this.$emit('cancel', true)
      },
      handleSubmit() {
        if (this.$refs.form.validate()) {
          const data = Object.assign({}, this.form)
          if (this.editStatus) {
            data.id = this.form.id
            this.$emit('update', data);
          } else {
            this.$emit('submit', data);
          }

        }
      }
    }
  }
</script>
